import css from "./ReferenceCardBuildingBlock.module.scss";
import Block from "@/components/atoms/Block";
import { Input } from "@mui/material";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import Add from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@mui/material";
import {
    type ReferenceCardsDto,
    useGetReferenceCardsQuery,
} from "@bespeak/apollo";
import { DndContext } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import Delete from "@mui/icons-material/Delete";
import type { DragEndEvent } from "@dnd-kit/core";
import type { GridPaginationModel, GridRowParams } from "@mui/x-data-grid";
import type { ReferenceCardBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import ReferenceCardOverview from "../../ReferenceCardOverview/ReferenceCardOverview";

export interface ReferenceCardBuildingBlockEditorComponentProps
    extends ReferenceCardBuildingBlock {
    onChange?: (buildingBlock: ReferenceCardBuildingBlock) => void;
}

export function ReferenceCardBuildingBlockComponent(
    props: ReferenceCardBuildingBlockEditorComponentProps,
) {
    const { t } = useTranslation("common", {
        keyPrefix: "reference-card-building-block",
    });

    type Fields = Pick<ReferenceCardBuildingBlock, "title">;

    const MAX_CARDS = 12;
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");
    const [modalOpen, setModalOpen] = useState(false);

    const [pagination, setPagination] = useState<GridPaginationModel>({
        pageSize: 8,
        page: 0,
    });

    const usePaginationChange = (pagination: GridPaginationModel) => {
        setPagination(pagination);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const { data, loading } = useGetReferenceCardsQuery({
        variables: {
            pagination: pagination,
        },
    });

    const addedCards = props?.cards;

    const titleError = resolveError({
        errors: props.errors,
        path: "title",
    });

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const handleChange =
        (key: keyof Fields) => (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange?.({
                ...props,
                [key]: event?.target?.value,
            });
        };

    const sortableCards = props?.cards;

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = sortableCards.findIndex((item) => {
                return item.id === active.id;
            });

            const newIndex = sortableCards.findIndex(
                (item) => item.id === over?.id,
            );

            props.onChange?.({
                ...props,
                cards: arrayMove(sortableCards, oldIndex, newIndex),
            });
        }
    }

    const handleOnRemoveItem = (index: number) => () => {
        props.onChange?.({
            ...props,
            cards: props.cards?.filter((_, i) => i !== index),
        });
    };

    const handleRowClick = (item: GridRowParams<any>) => {
        const existingCardIndex = addedCards.findIndex(
            (card) => card.id === item.row.id,
        );
        if (existingCardIndex !== -1) {
            props.onChange?.({
                ...props,
                cards: addedCards.filter(
                    (_, index) => index !== existingCardIndex,
                ),
            });

            return;
        }

        props.onChange?.({
            ...props,
            cards: [
                ...(props.cards ?? []),
                {
                    ...item.row,
                    id: item.row.id,
                    title: item.row.title,
                    //@ts-ignore
                    sortOrder: props.cards.length,
                },
            ],
        });

        if (addedCards.length >= MAX_CARDS - 1) {
            setModalOpen(false);
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        width: "70vw",
        overflowY: "scroll",
        margin: "0 auto",
    };

    const useAddModal = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
        setPagination({ pageSize: 8, page: 0 });
    };

    const addCard = (
        <>
            {props?.cards?.length < MAX_CARDS && (
                <IconButton onClick={useAddModal}>
                    <Add />
                </IconButton>
            )}
        </>
    );

    const ReferenceCardList = () => {
        return (
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                    items={sortableCards}
                    strategy={verticalListSortingStrategy}
                >
                    {sortableCards?.map((referenceCard, index) => {
                        return (
                            <SortableItem
                                key={referenceCard.id}
                                id={referenceCard.id}
                            >
                                <div
                                    className={clsx(
                                        "SortableItem",
                                        css.SortableItem,
                                    )}
                                >
                                    <span>{referenceCard.title}</span>
                                    <div
                                        className={clsx("Buttons", css.Buttons)}
                                    >
                                        <IconButton
                                            onClick={handleOnRemoveItem(index)}
                                            title={t("delete-item")}
                                        >
                                            <Delete />
                                        </IconButton>
                                        {sortableCards.length - 1 === index &&
                                            addCard}
                                    </div>
                                </div>
                            </SortableItem>
                        );
                    })}
                </SortableContext>
            </DndContext>
        );
    };

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <SelectBgColor
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                        value={bgColor}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <ValidationErrorLabel
                    keyPrefix={"reference-card-building-block"}
                    error={resolveError({
                        errors: props.errors,
                        path: "images",
                        type: "min",
                    })}
                    className={css.Error}
                />
                <Input
                    {...registerControl("title")}
                    placeholder={t("title-placeholder", "Titel")}
                    error={!!titleError}
                    style={{ margin: "20px 0px" }}
                />

                <Modal open={modalOpen} onClose={handleClose}>
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            {t("add-card-title")}
                        </Typography>
                        <Box>
                            <ReferenceCardOverview
                                data={
                                    data?.getReferenceCards as ReferenceCardsDto
                                }
                                onPaginationChanged={usePaginationChange}
                                addedCards={addedCards}
                                pagination={pagination}
                                loading={loading}
                                onAddItem={handleRowClick}
                                onRowClick={(row) => handleRowClick(row)}
                                {...props}
                            />
                        </Box>
                    </Box>
                </Modal>

                {props.cards?.length === 0 && addCard}
                <ReferenceCardList />
            </Block.Main>
        </Block>
    );
}

export default ReferenceCardBuildingBlockComponent;
