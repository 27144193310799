import FormControl from "@mui/material/FormControl";
import { Button } from "@bespeak/ui";
import { Cancel } from "@mui/icons-material";

interface ClearProps {
    onClick: () => void;
}

function Clear({ onClick }: ClearProps) {
    return (
        <FormControl>
            <Button
                variant="text"
                style={{
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 24,
                }}
                onClick={onClick}
                color="primary"
            >
                <Cancel
                    sx={{
                        width: 20,
                        height: 20,
                    }}
                />
            </Button>
        </FormControl>
    );
}

export default Clear;
