import { Select, SelectItem } from "@bespeak/ui";
import { type EducationalProgramDto } from "@bespeak/apollo";
import type { Key } from "@react-types/shared";
import css from "./ProgramDropdown.module.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useProgramStore } from "@/store/preferences";

type DropdownProps = {
    programs: EducationalProgramDto[] | undefined;
    onChange?: (groupId: Key) => void;
    onBlur?: () => void;
};

export function Dropdown(props: DropdownProps) {
    const { t } = useTranslation();
    const { programId } = useParams<{ programId: string }>();
    const programStore = useProgramStore();
    const storedProgramId = (programStore.value || "").toString();

    if (!props.programs) return;

    const options = props.programs;

    return (
        <Select
            items={options}
            onSelectionChange={props.onChange}
            defaultSelectedKey={
                programId || (storedProgramId ?? options.at(0)?.id)
            }
            onBlur={props.onBlur}
            className={css.select}
            style={{ width: "100%" }}
            placeholder={t("select-program", "Selecteer Programma")}
            label={t("select-program", "Selecteer Programma")}
        >
            {(item) => (
                <SelectItem key={item.id} id={item.id}>
                    <span className={css.SelectItem}>{item.name}</span>
                </SelectItem>
            )}
        </Select>
    );
}
