import { AlgoliaSearchProvider } from "@/provider/AlgoliaSearch";
import { SearchLayout } from "./SearchLayout.component";
import MainMenu from "@/components/organisms/MainMenu";

export const Search = () => {
    return (
        <AlgoliaSearchProvider applicationID="OKI9NGRWJU">
            <MainMenu />
            <SearchLayout />
        </AlgoliaSearchProvider>
    );
};

export default Search;
