import { useState, type ReactNode } from "react";
import { ApolloProvider } from "@apollo/client";
import useNetworkError from "./useNetworkError";
import useErrorLink from "./useErrorLink";
import useHttpLink from "./useHttpLink";
import useClient from "./useClient";
import { GraphQLError } from "@/router/errorboundary/ErrorBoundary";
import NetworkErrorNotice from "@/components/atoms/NetworkErrorNotice";

namespace GraphQLProvider {
    export interface Props {
        children: ReactNode;
    }

    export type GraphQLErrorType = {
        message: string;
        path?: string[];
        extensions?: {
            exception?: string;
        };
        locations?: {
            line: number;
            column: number;
        }[];
    };
}

export function GraphQLProvider(props: GraphQLProvider.Props) {
    const [networkError, setNetworkError] = useNetworkError();
    const [graphQlErrors, setGraphQlErrors] =
        useState<GraphQLProvider.GraphQLErrorType[]>();

    const errorLink = useErrorLink({
        setNetworkError,
        setGraphQlErrors,
    });

    const httpLink = useHttpLink();

    const client = useClient({
        errorLink,
        httpLink,
    });

    if (graphQlErrors) {
        const hasNotFoundException = graphQlErrors?.some(
            (error) => error?.extensions?.exception === "NotFoundException",
        );

        if (hasNotFoundException) {
            const errorMessage = graphQlErrors
                ?.map((error) => error.message)
                .join(", ");
            throw new GraphQLError(errorMessage);
        }
    }

    return (
        <ApolloProvider client={client}>
            {networkError ? <NetworkErrorNotice /> : null}
            {props.children}
        </ApolloProvider>
    );
}
